<template>
    <div class="bg-white text-xs mt-4 rounded-2xl">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                <div>
                    <div class="md:flex justify-between items-center">
                        <div>
                            <p class="font-bold text-base">Pelanggan</p>
                            <p class="text-gray-400 text-sm">Daftar pelanggan</p>
                        </div>
                        <div class="text-xs relative">
                            <div class="absolute top-2.5 left-2.5">
                                <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA"></unicon>
                            </div>

                            <input v-model="keyword" type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs bg-white`"
                                name="keyword" ref="keyword" id="keyword" @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                        </div>
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 dark:bg-gray-800">
                    <div v-for="item in data" :key="item.id" @click="goDetail(item)"
                        class="relative border border-gray-200 hover:border-transparent card-pelanggan rounded-2xl p-4 shadow-lg cursor-pointer transition-transform transform hover:-translate-y-1 bg-white ">
                        <!-- Image Section -->
                        <div class="flex justify-center items-center">
                            <img :src="item.foto ? default_url + item.foto : default_url + '/public/default.png'"
                                                alt="" class="w-15 rounded object-cover">
                        </div>

                        <!-- Name Section -->
                        <div class="mt-4 text-center">
                            <h3 class="text-md-center font-bold">{{ item.name }}</h3>
                        </div>

                        <!-- Date and Duration Section -->
                        <div class="mt-2 flex justify-around items-center">
                            <div class="flex items-start">
                                <unicon name="clock" class="mr-2" width="16" height="16" fill="#A1A1AA"></unicon>
                                <span v-if="item.days_expired >= 0" class="text-sm-right">
                                    {{ formatDuration(item.days_expired) }}
                                </span>
                                <span v-else class="text-sm text-red-500">
                                    Contract Expired
                                </span>
                            </div>
                        </div>

                        <!-- View Details Button -->
                        <button class="mt-4 w-full bg-blue-600 text-white rounded-lg py-2">View Details</button>
                    </div>
                </div>


                <div class="flex justify-end items-center mt-4">
                    <div class="flex items-center">
                        <div class="font-medium">
                            <span>{{ startNumber }}-{{ endNumber }} of {{ totalRows }}</span>
                        </div>
                        <div class="flex ml-4">
                            <unicon name="angle-left" class="cursor-pointer"
                                :fill="currentPage > 1 ? '#000000' : '#A1A1AA'" @click="previousPage()"></unicon>
                            <unicon name="angle-right" class="cursor-pointer ml-2"
                                :fill="totalRows == endNumber ? '#A1A1AA' : '#000000'" @click="nextPage()"></unicon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import globalMixin from '../../mixin/global'

export default {
    data: () => ({
        data: [],
        totalRows: 100,
        startNumber: 1,
        endNumber: 8,
        currentPage: 1,
        keyword: '',
        // default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {
        ...mapActions({
            setCustomer: 'device/setCustomer'
        }),
        nextPage() {
            if (this.endNumber < this.totalRows) {
                this.currentPage++
                this.getData()
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.getData()
            }
        },
        searchData() {
            this.currentPage = 1
            this.startNumber = 1
            this.getData()
        },
        async getData() {
            await this.axios.get(`v1/customer?page=${this.currentPage}&limit=8&keyword=${this.keyword}&status=1`, this.config)
                .then((ress) => {
                    this.data = ress.data.rows
                    this.totalRows = ress.data.count
                    if (this.totalRows <= 8) {
                        this.endNumber = this.totalRows
                    } else if (this.currentPage > 1) {
                        let page = this.currentPage - 1
                        this.startNumber = page * 8 + 1
                        this.endNumber = this.currentPage * 8
                        if (this.totalRows < this.endNumber) {
                            this.endNumber = this.totalRows
                        }
                    } else {
                        this.startNumber = 1
                        this.endNumber = 8
                    }
                })
                .catch((err) => {
                    this.errorResponse(err)
                })
        },
        formatDate(dateString) {
            // Convert the date string into a Date object
            const date = new Date(dateString);

            // Define the options for formatting
            const options = { day: 'numeric', month: 'short', year: 'numeric' };

            // Format the date into "26 Nov 2024"
            return date.toLocaleDateString('en-GB', options);
        },
        formatDuration(days) {
            const years = Math.floor(days / 365);
            const remainingDaysAfterYears = days % 365;
            const months = Math.floor(remainingDaysAfterYears / 30);
            const remainingDays = remainingDaysAfterYears % 30;

            let formattedDuration = '';

            // Construct the formatted string
            if (years > 0) {
                formattedDuration += `${years} year${years > 1 ? 's' : ''} `;
            }
            if (months > 0) {
                formattedDuration += `${months} month${months > 1 ? 's' : ''} `;
            }
            if (remainingDays > 0 || (!years && !months)) {
                formattedDuration += `${remainingDays} day${remainingDays !== 1 ? 's' : ''}`;
            }

            return formattedDuration.trim();
        },
        goDetail(item) {
            this.setCustomer(item)
        }
    },
    computed: {
        ...mapGetters({
            token: 'auth/token'
        }),
    },
    created() {
        this.getData()
    }
}
</script>
