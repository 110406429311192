<template>
    <div class="p-8">
        <div class="rounded-2xl bg-blue-50 w-full flex justify-between items-center content-center border border-blue-400 "
            v-if="detail && detail.days_expired <= 60 && detail.days_expired > 30">
            <div class="py-4 px-8">
                <p class="text-xl font-bold text-blue-500">Masa berlaku kontrak tersisa {{ detail.days_expired }} hari
                </p>
                <p class="text-blue-500 w-96 text-sm mt-2 leading-6">“jika masa berlaku habis maka dashboard tidak akan
                    bisa di akses”</p>
            </div>
            <div class="pr-6 pt-4">
                <img src="/img/Ilustration_Hand_Dashboard_.svg" alt="">
            </div>

        </div>
        <div class="rounded-2xl bg-yellow-50 w-full flex justify-between items-center content-center border border-yellow-400 "
            v-else-if="detail && detail.days_expired <= 30 && detail.days_expired > 15">
            <div class="py-4 px-8">
                <p class="text-xl font-bold text-yellow-500">Masa berlaku kontrak tersisa {{ detail.days_expired }} hari
                </p>
                <p class="text-yellow-500 w-96 text-sm mt-2 leading-6">“jika masa berlaku habis maka dashboard tidak
                    akan bisa di akses”</p>
            </div>
            <div class="pr-6 pt-4">
                <img src="/img/Ilustration_Hand_Dashboard_yellow.svg" alt="">
            </div>

        </div>
        <div class="rounded-2xl bg-red-50 w-full flex justify-between items-center content-center border border-red-400 "
            v-else-if="detail && detail.days_expired <= 15">
            <div class="py-4 px-8">
                <p class="text-xl font-bold text-red-500">Masa berlaku kontrak tersisa {{ detail.days_expired }} hari
                </p>
                <p class="text-red-500 w-96 text-sm mt-2 leading-6">“jika masa berlaku habis maka dashboard tidak akan
                    bisa di akses”</p>
            </div>
            <div class="pr-6 pt-4">
                <img src="/img/Ilustration_Hand_Dashboard_red.svg" alt="">
            </div>

        </div>
        <div class="rounded-2xl bg-white w-full flex justify-between items-center content-center" v-else>
            <div class="py-4 px-8">
                <p class="text-xl font-bold">Halo, <span class="text-blue-500">{{ user.name }}</span></p>
                <p class="text-gray-400 md:w-1/2 text-sm mt-2 leading-6">
                    “{{ quotes ? quotes : 'This is should be random quotes of the day, it will made your day more fun.Don’t be too serious.'}}”
                </p>
            </div>
            <div class="pr-6 pt-4">
                <img src="/img/Ilustration_Hand_Dashboard_.svg" alt="">
            </div>

        </div>

        <div class="grid grid-cols-12 gap-4 mt-4">
            <!-- //pengecualian user krakatau -->
            <div :class="`${status == 3 && !customer ? 'col-span-12 md:col-span-3' : 'col-span-12 md:col-span-4'} bg-white rounded-2xl py-4 px-8`"
                v-if="(status == 1 || status == 3) && !customer && !is_krakatau">
                <div class="flex items-center">
                    <div class="text-white mr-4">
                        <div class="h-12 w-12 bg-blue-50 rounded-full text-2xl flex items-center justify-center"
                            style="">
                            <unicon name="user" class=" " fill="royalblue"></unicon>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-lg font-bold leading-6">{{ total_customer }}</p>
                        <p class="text-gray-400 text-sm font-medium">Total Pelanggan</p>

                    </div>
                </div>
            </div>
            <!-- //pengecualian user krakatau -->
            <div :class="`${status == 3 && !customer ? 'col-span-12 md:col-span-3' : 'col-span-12 md:col-span-4'} bg-white rounded-2xl py-4 px-8`"
                v-if="status == 3 && !customer && !is_krakatau">
                <div class="flex items-center">
                    <div class="text-white mr-4">
                        <div class="h-12 w-12 bg-red-50 rounded-full text-2xl flex items-center justify-center"
                            style="">
                            <unicon name="sitemap" class=" " fill="#EF4444"></unicon>
                        </div>
                    </div>

                    <div class="w-full">
                        <p class="text-lg font-bold leading-6">{{ total_reseller }}</p>
                        <p class="text-gray-400 text-sm font-medium">Total Reseller</p>

                    </div>
                </div>
            </div>

            <div
                :class="`${status == 3 && !customer ? 'col-span-12 md:col-span-3' : 'col-span-12 md:col-span-4'} bg-white rounded-2xl py-4 px-8`">
                <div class="flex items-center">
                    <div class="text-white mr-4">
                        <div class="h-12 w-12 bg-purple-50 rounded-full  text-2xl flex items-center justify-center"
                            style="">
                            <unicon name="monitor" class=" " fill="#8B5CF6"></unicon>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-lg font-bold leading-6">{{ total_perangkat }}</p>
                        <p class="text-gray-400 text-sm font-medium">Total Perangkat</p>

                    </div>
                </div>
            </div>
            <!-- //pengecualian user krakatau -->
            <div :class="`${status == 3 && !customer ? 'col-span-12 md:col-span-3' : 'col-span-12 md:col-span-4'} bg-white rounded-2xl py-4 px-8`"
                v-if="!is_krakatau">
                <div class="flex items-center">
                    <div class="text-white mr-4">
                        <div class="h-12 w-12 bg-green-50 rounded-full  text-2xl flex items-center justify-center"
                            style="">
                            <unicon name="users-alt" class=" " fill="#10B981"></unicon>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-lg font-bold leading-6">{{ total_user }}</p>
                        <p class="text-gray-400 text-sm font-medium">Total Pengguna</p>

                    </div>
                </div>
            </div>

            <div :class="`${status == 3 && !customer ? 'col-span-12 md:col-span-3' : 'col-span-12 md:col-span-4'} bg-white rounded-2xl py-4 px-8`"
                v-if="status == 2 || customer || user.role_id === 35">
                <div class="flex items-center">
                    <div class="text-white mr-4">
                        <div class="h-12 w-12 bg-red-50 rounded-full  text-2xl flex items-center justify-center"
                            style="">
                            <unicon name="clock" class=" " fill="#EF4444"></unicon>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="text-lg font-bold leading-6"> {{ hours > 9 ? hours : `0${hours}` }} : {{ minutes }} :
                            {{ seconds }}</p>
                        <p class="text-gray-400 text-sm font-medium">Jam Sekarang</p>

                    </div>
                </div>
            </div>
        </div>
        <div v-if="user.role_id === 35">
            <mapDashboard />
        </div>
        <div v-else-if="user.customer_id || customer">
            <mapDashboard />
        </div>
        <div v-else-if="!customer">
            <customerDashboard />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import globalMixin from '../mixin/global'
import chartMulti from '../components/global/chartDashboard.vue'
import avgChart from '@/components/dashboard/avgChart'
import mapDashboard from '@/components/dashboard/mapDashboard'
import customerDashboard from '@/components/dashboard/customerDashboard'
export default {
    data: () => ({
        device: [],
        hours: 0,
        minutes: 0,
        seconds: 0,
        dialog: false,
        loadingChart: false,
        is_krakatau: false,
        total_user: 0,
        total_reseller: 0,
        total_customer: 0,
        total_perangkat: 0,
        totalRows: 0,
        quotes: '',
        detailDevice: {
            devices: {},
            latitude: '',
            longitude: '',
            name: '',
            last_update: ''

        },
    }),
    components: {
        chartMulti, avgChart, mapDashboard, customerDashboard
    },
    mixins: [globalMixin],
    methods: {
        ...mapActions({
            setUser: 'auth/setUser',
            setCustomer: 'device/setCustomer',

        }),
        setTime() {
            setInterval(() => {
                const date = new Date()
                this.hours = date.getHours()
                this.minutes = this.checkSingleDigit(date.getMinutes())
                this.seconds = this.checkSingleDigit(date.getSeconds())
            }, 1000)
        },

        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2)
        },

        getDashboard() {
            let customer_id = this.customer ? this.customer.id : ''
            this.axios.get('v1/dashboard?customer_id=' + customer_id, this.config)
                .then((ress) => {
                    this.total_user = ress.data.user
                    this.total_customer = ress.data.customer
                    this.total_reseller = ress.data.reseller
                    this.total_perangkat = ress.data.total_perangkat
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        localStorage.removeItem('user')
                        localStorage.removeItem('token')
                        this.setUser({ user: null, token: null })
                    }
                })
        },
        getRandomQuotes() {
            let api = 'https://api.quotable.io/quotes/random'
            this.axios.get(api)
                .then((ress) => {
                    this.quotes = ress.data[0].content
                    console.log("Random Quotes", ress.data[0].content)
                }).catch((err) => {
                    this.quotes = false;
                    console.log(err)
                })
        },
        back() {
            this.setCustomer('')
        }

    },
    created() {
        this.getDashboard()
        this.setTime()
        this.getRandomQuotes()
        this.is_krakatau = this.isKrakatauUser;
        console.log(this.detail);

    },
    computed: {
        ...mapGetters({
            status: 'auth/status',
            detail: 'auth/detail',
            customer: 'device/customer',
        }),
        isKrakatauUser() {
            console.log("is karkatau 2", this.user)
            this.is_krakatau = this.user && this.user.name === "Krakatau Smart" && this.user.role_id === 35;
        },
    },
    watch: {
        customer: function (newQuestion, oldQuestion) {
            this.getDashboard()
        }
    },
    beforeDestroy() {
        this.sockets.unsubscribe('updatedatachart');
    }
}
</script>